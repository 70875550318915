import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Components
import Header from "../components/global/header";
import "../components/styles/menu.css";
import Opportunity from "../components/icons/opportunity.svg";
import { JobContactForm } from "../components/job/job-contact-form";

const FlexContainer = styled.div`
  padding: 0 30px;

  & h2 {
    letter-spacing: 0;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  @media (max-width: 600px) {
    & h1 {
      font-size: 20px;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  margin: 0 -30px;

  @media (max-width: 767px) {
    margin: 0 -20px;
  }
`;

const Container = styled.div`
  max-width: 700px;
  flex: calc(100% - 700px - 450px);
  padding: 50px 30px;

  @media (max-width: 1100px) {
    width: 100%;
    flex: 100%;
    padding: 20px;
  }
`;

const TextBox = styled.div`
  width: 100%;
  max-width: 450px;
  color: #fff;

  & a {
    color: #fff;

    margin: 0;

    font-size: 14px;
  }

  padding: 50px 30px;

  @media (max-width: 1100px) {
    padding: 20px;
    max-width: 700px;
  }

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const SectionTitle = styled.div`
  & a {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-self: flex-start;

    padding: 30px;

    @media (max-width: 767px) {
      padding: 20px;
    }

    & img {
      height: 55px;
      width: auto;

      @media (max-width: 500px) {
        height: 30px;
      }
    }
  }
`;

const JobsContainer = styled.div`
  padding: 15px;

  // background-color: #8b50d5;
  background-color: ${props => props.bgColor};
  // color: #fff;

  & h2 {
    margin: 0;
  }

  & p:nth-child(2) {
    margin-top: 0;
  }

  & h2 {
    font-size: 16px;
    line-height: 22px;

    font-weight: 600;
  }
`;

const JobListing = ({ data }) => {
  const [bgColor, setBgColor] = useState(`#8b50d5`);

  useEffect(() => {
    // 1 - #8b50d5
    // 2 - #4bc2f1
    // 3 - #7abc67
    // 4 - #ffc706
    // 5 - #e84b27

    const allJobUids = data.prismicJobs.data.job_order
      .filter(job => job.jobs.uid !== null)
      .map(job => job.jobs.uid);
    const positionInArray = allJobUids.indexOf(data.prismicJobListing.uid);

    // This is some truly awful code
    if (
      positionInArray === 0 ||
      positionInArray === 5 ||
      positionInArray === 10 ||
      positionInArray === 15
    ) {
      setBgColor(`#8b50d5`);
    }
    if (
      positionInArray === 1 ||
      positionInArray === 6 ||
      positionInArray === 11 ||
      positionInArray === 16
    ) {
      setBgColor(`#4bc2f1`);
    }
    if (
      positionInArray === 2 ||
      positionInArray === 7 ||
      positionInArray === 12 ||
      positionInArray === 17
    ) {
      setBgColor(`#7abc67`);
    }
    if (
      positionInArray === 3 ||
      positionInArray === 8 ||
      positionInArray === 13 ||
      positionInArray === 18
    ) {
      setBgColor(`#ffc706`);
    }
    if (
      positionInArray === 4 ||
      positionInArray === 9 ||
      positionInArray === 14 ||
      positionInArray === 19
    ) {
      setBgColor(`#e84b27`);
    }
  }, []);

  return (
    <>
      <Header />
      <SectionTitle color={"#fff"}>
        <Link to={`/`}>
          <img
            src={data.prismicHomepage.data.svg_text.url}
            alt={`Spark Icon`}
          />
          <img src={Opportunity} alt={`Spark Icon`} />
        </Link>
      </SectionTitle>

      <FlexContainer>
        <FlexRow>
          <TextBox>
            <Link to={`/#current-jobs`}>← Back To Jobs</Link>
            <div
              color={"#fff"}
              dangerouslySetInnerHTML={{
                __html: data.prismicJobs.data.text.html,
              }}
            />

            <JobContactForm
              gdprText={data.prismicContact.data.gdpr_text}
              jobTitle={data.prismicJobListing.data.job_title.text}
            />
          </TextBox>

          <Container>
            <JobsContainer bgColor={bgColor}>
              <div className="single-job-inner">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.prismicJobListing.data.job_title.html,
                  }}
                />
                <p>{data.prismicJobListing.data.job_subheading}</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.prismicJobListing.data.job_text.html,
                  }}
                />
                <p>{data.prismicJobListing.data.job_location}</p>
              </div>
            </JobsContainer>
          </Container>
        </FlexRow>
      </FlexContainer>
    </>
  );
};

export default JobListing;

export const pageQuery = graphql`
  query Job($uid: String!) {
    prismicJobs {
      data {
        title {
          html
        }
        text {
          html
        }
        job_order {
          jobs {
            uid
          }
        }
      }
    }
    prismicHomepage {
      data {
        svg_text {
          url
        }
      }
    }
    prismicContact {
      data {
        gdpr_text {
          html
        }
      }
    }
    prismicJobListing(uid: { eq: $uid }) {
      uid
      data {
        job_subheading
        job_excerpt {
          html
        }
        job_text {
          html
        }
        job_title {
          html
          text
        }
        job_location
      }
    }
  }
`;
