import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

// Utils
import { encode } from "../utils/encode-form";

const Form = styled.form`
  width: 100%;
  max-width: 450px;

  label,
  span {
    width: 100%;
    display: block;
    font-size: 14px;
  }

  input {
    color: #fff;
    background-color: transparent;

    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #fff;

    padding: 0;

    width: 100%;
    max-width: 450px;
  }

  input[type="file"] {
    border: 0;
    font-size: 14px;
  }

  input[type="checkbox"] {
    width: auto;
  }

  input:focus {
    outline: 0;
  }

  .custom-file-input {
    color: transparent;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "Choose File";
    color: #fff;
    display: inline-block;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 0;
    padding: 5px 10px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: none;
  }
  .custom-file-input:hover::before {
    background-color: #fff;
    color: #0a2a2f;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }

  button {
    background-color: transparent;
    padding: 5px 10px;
    border: 1px solid #fff;
    color: #fff;

    transition: 300ms all ease;

    &:hover {
      background-color: #fff;
      color: #0a2a2f;
    }
  }

  .gdpr-text {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    & > div {
      & > p {
        font-size: 14px;
        line-height: 18px;

        margin: 0;
        padding: 0 0 0 10px;

        & a {
          text-decoration: underline;
        }
      }
    }
  }
`;

export const JobContactForm = ({ gdprText, jobTitle }) => {
  const [formMessage, setFormMessage] = useState(null);

  // Form Setup
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      job: jobTitle,
      name: "",
      telephone: "",
      email: "",
      cv: "",
      gdprCheckbox: false,
    },
  });

  const onSubmit = (data, e) => {
    const formattedData = {
      job: jobTitle,
      name: data.name,
      telephone: data.telephone,
      email: data.email,
      cv: data.cv[0],
    };

    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": "job-enquiry",
        ...formattedData,
      }),
    })
      .then(() => {
        setFormMessage(
          "Thank you for submitting your details. We will be in touch soon."
        );
        e.target.reset();
      })
      .catch(error => {
        console.log("form error:", error);
        setFormMessage(
          "There was an issue submitting your form. Please contact us at hello@sparkopportunities.com"
        );
      });
  };

  return (
    <>
      <Form
        name="job-enquiry"
        method="POST"
        data-netlify="true"
        onSubmit={handleSubmit(onSubmit)}
        netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="job-enquiry" />

        <input
          name="job"
          type="text"
          value={jobTitle}
          ref={register}
          type="hidden"
        />

        <p>
          <label htmlFor="name">Name</label>
          {/* errors will return when field validation fails  */}
          {errors.name && <span>This field is required</span>}
          <input
            name="name"
            type="text"
            autoComplete="name"
            ref={register({ required: true, maxLength: 80 })}
          />
        </p>
        <p>
          <label htmlFor="telephone">Phone</label>
          {/* errors will return when field validation fails  */}
          {errors.telephone && <span>This field is required</span>}
          <input
            name="telephone"
            type="tel"
            autoComplete="telephone"
            ref={register}
          />
        </p>
        <p>
          <label htmlFor="email">Email</label>
          {/* errors will return when field validation fails  */}
          {errors.email && <span>This field is required</span>}
          <input
            name="email"
            type="email"
            autoComplete="email"
            ref={register({
              required: true,
            })}
          />
        </p>
        <p>
          <label htmlFor="cv">Upload CV</label>
          {/*https://github.com/react-hook-form/react-hook-form/blob/master/examples/customValidation.tsx*/}
          {errors.cv && errors.cv.type === "fileSize" && (
            <span>
              File is too large. The file size limit is 300kb. Please email us
              at{" "}
              <a href="mailto:hello@sparkopportunities.com">
                hello@sparkopportunities.com
              </a>
            </span>
          )}
          <input
            type="file"
            name="cv"
            accept=".jpg, .jpeg, .pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            className="custom-file-input"
            ref={register({
              validate: {
                fileSize: value =>
                  value[0] !== undefined && value[0].size < 300000,
              },
            })}
          />
        </p>
        <div className="gdpr-text">
          {/* errors will return when field validation fails  */}
          {errors.gdprCheckbox && <span>This field is required</span>}
          <div className="squaredFour">
            <input
              type="checkbox"
              id="job-enquiry-form-gdpr"
              name="job-enquiry-form-gdpr"
              value={true}
              checked={true}
              defaultChecked={true}
              ref={register({ required: true })}
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: gdprText.html,
            }}
          />
        </div>
        <p>
          <button type="submit">Submit</button>
        </p>
        <input type="hidden" name="bot-field" />
      </Form>

      {formMessage !== null && <p>{formMessage}</p>}
    </>
  );
};
